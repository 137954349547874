.filepond--wrapper.grid .filepond--item {
  width: 220px;
  height: 220px;
}

.filepond--wrapper.filepond--cover .filepond--item {
  height: 335px;
}

.filepond--file-action-button {
  cursor: pointer;
}

.filepond--panel-root {
  background-color: $tno-blue;
}

.filepond--drip-blob {
  background-color: red;
}

.filepond--drop-label {
  color: yellow;
}

.filepond--image-preview {
  background-color: #333;
}

[data-filepond-item-state="processing-complete"] .filepond--item-panel {
  background-color: $tno-blue !important;
}
