.notification {
  padding: 1em 1em 0.9em 3em;
  margin: 1em 0;
  background-color: $grey-light;
  font-weight: bold;
  border: 0.5px solid darken($grey-light, 10%);
  background-image: url("../../public/alert-triangle.svg");
  background-repeat: no-repeat;
  background-position: 1em 1em;
  background-size: 1.3em;

  &.success {
    background-color: #f6fef9;
    border-color: darken(#f6fef9, 10%);
    background-image: url("../../public/check-circle.svg");
  }
}
