main.stats-pages {
  .view {
    width: 100% !important;
    margin: 0 !important;
    max-width: 100% !important;
  }

  section.main {
    width: $full-width;
    max-width: calc(100% - 2rem);
    margin: 1rem auto 4rem;
    padding: 100px 0 50px 0;

    @media (max-width: $medium-width) {
      padding: 20px 0;
      margin: 1rem auto;
    }

    h1 {
      font-size: 90px;
      margin-bottom: 50px;

      @media (max-width: $medium-width) {
        font-size: 40px;
        margin-bottom: 20px;
      }
    }
  }
  section.stat-card {
    background: white;
    padding: 75px 0;

    @media (max-width: $medium-width) {
      padding: 1rem 0;
    }

    &:nth-child(odd) {
      background: #f7f8f9;
    }

    & > * {
      margin: 1rem auto 4rem;
      max-width: $full-width;

      @media (max-width: $medium-width) {
        margin: 1rem auto;
        max-width: calc(100% - 2rem);
        // overflow: hidden;
      }
    }

    h2 {
      font-size: 60px;
      margin-bottom: 1rem;

      @media (max-width: $medium-width) {
        font-size: 30px;
      }
    }
  }
  section.stat-card {
    .description {
      padding-right: 33%;
      font-size: 0.9em;
      color: $grey;
      margin-bottom: 3rem;

      @media (max-width: $medium-width) {
        padding-right: 0;
      }
    }
    p {
      font-size: 15px;
    }
  }
}
