.field {
  .error-message {
    color: $error;
    margin-top: 0.5em;
    font-size: 0.8em;
  }

  label {
    color: $grey;
    display: block;
    margin-bottom: 0.5rem;
  }
  input:not([type="checkbox"]),
  select,
  textarea {
    color: black;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-bottom: 1px solid black;
    max-width: 100%;

    &:active,
    &:focus {
      border-color: $tno-blue;
    }

    &:invalid {
      border-color: #bd5353;
      box-shadow: none;
      color: #bd5353;
    }
  }
  input[type="checkbox"] {
    height: 20px;
    width: 20px;
    display: inline-block;
    background-color: $grey;
    border-radius: 4px;
    position: relative;
    top: -3px;

    &:checked {
      background-color: $tno-blue;
      background-image: url("../../public/check.svg");
      background-repeat: no-repeat;
      background-size: 20px;
    }
  }
  textarea {
    width: 100%;
  }
  select {
    height: 33px;
    width: 100%;
    overflow: hidden;
    background-image: url("/images/icon-dropdown.png"); // no-repeat right #ddd;
    background-repeat: no-repeat;
    background-position: calc(100% - 7px) 50%;
  }
  & > p {
    margin-top: 1.5rem;
  }

  & + .field {
    margin-top: 2rem;
  }

  & .shared {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div > input[type="checkbox"] {
      position: absolute;
      top: 4px;
      margin: 0;
    }
    > div > input[type="checkbox"] + input[type="text"] {
      padding-left: 26px;
    }

    > div {
      flex: 0 1 48%;
      position: relative;

      label {
        font-size: 0.9em;
      }
    }
  }
}

.from-horizontal {
  display: flex;

  @media (max-width: $medium-width) {
    display: block;
  }

  .field + .field {
    margin-left: 2rem;
    margin-top: 0;

    @media (max-width: $medium-width) {
      margin-top: 2rem;
      margin-left: 0;
    }
  }

  .field--button {
    margin-top: 0.8rem;

    @media (max-width: $medium-width) {
      margin-top: 0;
    }
  }
}

.button,
button,
input[type="submit"] {
  background-color: $tno-blue;
  border: none;
  padding: 1rem 2rem;
  font-size: 18px;
  color: white;
  cursor: pointer;
  font-weight: bold;
  display: block;
  text-decoration: none;
  user-select: none;

  &[disabled],
  &.button--disabled {
    background-color: $disabled-bg-color;
    color: $disabled-text-color;
    cursor: not-allowed;
  }
}

.error {
  input {
    border-color: red;
  }

  label {
    color: red;
  }

  .error-message {
    color: red;
  }
}

.flatpickr-input {
  height: 33px;
}

.loading-form {
  background-image: url("../../public/loading.gif");
  background-position: center;
  background-size: 125px;
  background-repeat: no-repeat;

  .field,
  .field > input {
    opacity: 0.2;
    cursor: default;
  }
}

select.styled {
  display: inline-block;
  padding: 5px 40px 5px 5px !important;
  max-width: 100%;
  height: auto !important;
  border: 1px solid #e3e3e3;
  border-radius: 3px;
  background: url("../../public/selectbox-arrow.png") right center no-repeat;
  background-color: #fff;
  color: #444444;
  font-size: 12px;
  line-height: 16px !important;
  appearance: none;
  /* this is must */
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0 5px;
}
/* body select.select_box option */
select.styled option {
  padding: 0 4px;
}
/* for IE and Edge */
select.styled::-ms-expand {
  display: none;
}
select.styled:disabled::-ms-expand {
  background: #f60;
}
