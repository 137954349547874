$grey-light: rgb(245, 244, 244);
$grey-lighter: rgb(202, 202, 202);
$grey: rgb(138, 138, 138);
$grey-darker: rgb(100, 100, 100);
$grey-dark: rgb(46, 46, 46);

$text-color: $grey-dark;
$hover-bg-color: $grey-light;
$border-color: $grey-lighter;

$tno-blue: #538cbd;
$tno-pink: #cf2576;

$muted: #bec0c1;
$error: #bd5353;

$disabled-text-color: #dddfe0;
$disabled-bg-color: #bfc1c2;

$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$full-width: 1000px;

$grey-color: #69696e;
$pink-color: #c20764;
$yellow-color: #f7c209;
$blue-color: #538cbd;
$green-color: #6fbe49;
