.horizontal-list {
  padding: 0;
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 0 0 auto;

    & + li {
      margin-left: 1.5rem;
    }
  }
}

.loading-chart {
  background-image: url("../../public/loading.gif");
  background-position: center;
  background-size: 125px;
  background-repeat: no-repeat;

  & > * {
    opacity: 0.2;
    cursor: default;
  }
}

.negative {
  color: $tno-blue;

  &::before {
    display: inline-block;
    margin-right: 1rem;
    content: "↓";
  }
}

.positive {
  color: $tno-pink;

  &::before {
    display: inline-block;
    margin-right: 0.5rem;
    content: "↑";
  }
}

.legend {
  li {
    color: #8a8a8a;
    font-size: 0.9rem;
  }
  li::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 1000px;
    margin-right: 0.5rem;
  }
  li:nth-of-type(1)::before {
    background-color: #538cbd;
  }
  li:nth-of-type(2)::before {
    background-color: #093f5f;
  }
  li:nth-of-type(3)::before {
    background-color: #cf2576;
  }
  li:nth-of-type(4)::before {
    background-color: #73a00e;
  }
}
