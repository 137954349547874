table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

th {
  text-align: left;
  color: $tno-blue;
  font-size: 18px;
}

tr.inactive td {
  color: $grey;

  .badge {
    color: black;
    text-decoration: underline;
    font-weight: normal;
    float: right;
    font-size: 0.9em;
  }
}

tr:hover {
  td {
    color: $tno-pink;
  }
}
thead tr:hover {
  background-color: transparent;
}

td,
th {
  padding: 0.5rem;
  height: 3rem;
  vertical-align: middle;
  font-weight: bold;

  &:first-of-type {
    padding-left: 0;
  }
  &:last-of-type {
    padding-right: 0;
  }
}

td {
  border-bottom: 1px solid $border-color;
  min-width: 100px;

  &.actions {
    a {
      font-weight: normal;
      text-decoration: underline;
      font-size: 0.9em;
      margin: 0 5px;

      &:hover {
        color: $tno-pink;
      }
    }
  }
}
