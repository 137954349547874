// Margin classes
.m-0 {
  margin: 0 !important;
}
.m-05 {
  margin: 0.5rem !important;
}

.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}

.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 2rem !important;
}
.mr-3 {
  margin-right: 3rem !important;
}
.mr-4 {
  margin-right: 4rem !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-3px {
  margin-bottom: 3px !important;
}

.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 2rem !important;
}
.ml-3 {
  margin-left: 3rem !important;
}
.ml-4 {
  margin-left: 4rem !important;
}
.ml-a {
  margin-left: auto !important;
}

.ml-a {
  margin-left: auto !important;
}
.mr-a {
  margin-right: auto !important;
}

// Padding classes
.p-0 {
  padding: 0 !important;
}
.p-05 {
  padding: 0.5rem !important;
}
.p-1 {
  padding: 1rem !important;
}

.pr-0 {
  padding-right: 0 !important;
}
.pr-2 {
  padding-right: 2 !important;
}

.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 1rem !important;
}
.pl-2 {
  padding-left: 2rem !important;
}
.pl-3 {
  padding-left: 3rem !important;
}
.pl-4 {
  padding-left: 4rem !important;
}

// Text classes
.ta-r {
  text-align: right !important;
}
.td-n {
  text-decoration: none !important;
}

// Visible classes
.hidden {
  display: none;
}

// Cursors
[title] {
  cursor: help;
}
[disabled] {
  cursor: not-allowed;
}
a {
  cursor: pointer;
}

// Miscellaneous classes
.error {
  background-color: $error;
  border-radius: 8px;
  padding: 0.6rem 0.8rem 0.5rem;
  color: white;
}
.readable-width {
  max-width: 700px;
}

.split {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    flex: 0 0 auto;
  }
  & > *:first-child {
    flex: 0 1 auto;
  }
}

.two-columns,
.three-columns {
  display: flex;
  justify-content: space-between;
  align-items: start;
  flex-wrap: wrap;

  &.responsive > * {
    overflow: hidden;

    @media (max-width: $medium-width) {
      width: 100% !important;
      + * {
        margin-top: 2rem;
      }
    }
  }
}

.two-columns {
  & > * {
    width: calc(50% - 2rem);
  }

  &.no-gab > * {
    width: 50%;
  }
}

.three-columns {
  & > * {
    width: calc(100% / 3 - 2rem);
  }

  &.no-gab > * {
    width: calc(100% / 3);
  }
}

.data-header {
  display: flex;
  border-bottom: 1px solid $grey-lighter;
  align-items: baseline;

  h3 {
    font-size: 30px;
    margin-right: auto;
    line-height: 30px;
    margin-bottom: 5px;

    small {
      margin-left: 1px;
      font-size: 18px;
      line-height: 18px;
    }
  }

  p {
    color: $grey-darker;
    font-size: 15px;
  }

  & > * {
    margin: 0;
  }
}

.hidden-on-mobile {
  @media (max-width: $medium-width) {
    display: none;
  }
}

sub,
sup {
  margin-left: 1px;
  vertical-align: unset;
  display: inline-block;
  font-size: 90%;
}
sub {
  transform: translateY(15%);
}
sup {
  transform: translateY(-20%);
}

.loading-message {
  padding-top: 5rem;
  text-align: center;
}
