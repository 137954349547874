.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.modal-container {
  width: 500px;
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;

  max-height: calc(100vh - 2rem);
  overflow-y: auto;

  > :last-child {
    padding-bottom: 1.5rem;
  }
}

.modal-header h3 {
  margin-top: 0;
  color: $tno-blue;
}

.modal-body {
  margin: 20px 0;

  :first-child,
  :first-child > :first-child {
    margin-top: 0;
  }

  :last-child,
  :last-child > :last-child {
    margin-bottom: 0;
  }

  input:not([type="checkbox"]) {
    width: 100%;
  }
}

.modal-footer {
  align-self: flex-end;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
