h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
input,
textarea,
a,
label,
th,
td,
.modal-container,
.flatpickr-calendar,
.filepond--root,
.tooltip,
.hub {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-wrap: break-word;
  color: $text-color;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 110%;

  &.alt {
    color: $tno-blue;
  }
}

h1 {
  font-size: 60px;
  @media (max-width: $medium-width) {
    font-size: 40px;
  }
}
h2 {
  font-size: 40px;
  @media (max-width: $medium-width) {
    font-size: 30px;
  }
}
h3 {
  font-size: 30px;
  @media (max-width: $medium-width) {
    font-size: 24px;
  }
}
h4 {
  font-size: 24px;
  @media (max-width: $medium-width) {
    font-size: 18px;
  }
}

a {
  color: black;
  text-decoration: underline;
}

p {
  margin-top: 1rem;
  margin-bottom: 1rem;

  &.big {
    font-size: 24px;
  }
}

.muted {
  color: $grey;
}
a.muted:hover {
  color: $grey !important;
}

code {
  background-color: $grey-light;
  padding: 0.3rem 0.7rem;
  border-radius: 3px;
  font-size: 16px;
}

.page {
  img {
    max-width: 100%;
  }
  ul {
    padding-left: 1rem;
  }
  li p {
    margin: 0;
  }

  h2,
  h3,
  h4 {
    margin-top: 2rem;

    & + * {
      margin-top: 0.7rem;
    }
  }

  h3 {
    font-size: 22px;
    color: $tno-blue;

    & + * {
      margin-top: 0.5rem;
    }
  }

  img {
    border-radius: 3px;
  }

  margin-bottom: 2rem;

  & > *:first-child {
    margin-top: 0;

    & > *:first-child {
      margin-top: 0;
    }
  }
  & > *:last-child {
    margin-bottom: 0;

    & > *:last-child {
      margin-bottom: 0;
    }
  }
}
